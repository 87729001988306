<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        用户管理
      </template>
      <template #input>
        <!-- 输入框前文字样式：class="tag" -->
        <span class="tag">注册时间: </span>
        <!-- input框固定长度为240px -->
        <a-input placeholder="请选择注册时间" />
        <a-input placeholder="请输入关键字" />
        <!-- 选择框固定长度为193px 选择框后缀图标稍后设置-->
        <a-select
          default-value="lucy"
          @change="handleChange"
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option value="lucy">
            行大运
          </a-select-option>
          <a-select-option
            value="disabled"
            disabled
          >
            暴富
          </a-select-option>
        </a-select>
        <a-button class="all_boder_btn">搜索</a-button>
      </template>
    </HeaderBox>

    <a-table
      class="table-template"
      :row-selection="{
       selectedRowKeys: selectedRowKeys,
       onChange: select,
       onSelectAll: selectAll,
       columnTitle:'选择'
      }"
      rowKey="key"
      :columns="columns"
      :data-source="tableData"
      :pagination="{
        total: total,
        showTotal: (res) => `共${total}条`,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50', '100'],
      }"
    >
      <template slot="footer">
        <a-checkbox @change="onChange" :checked="isChecked" class="allSellect">
          全选
        </a-checkbox>
      </template>
      <template
        slot="index"
        slot-scope="item, row, index"
      >
        <!-- {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }} -->
        {{index + 1}}
      </template>

      <template slot="operation">
        <span class="blueText">
          查看详情
        </span>
      </template>
    </a-table>

    <div>
      1.是否可选：row-selection<br><br>2.是否含表尾：slot="footer"<br><br>3.操作栏蓝色字体：class="blueText"
    </div>

  </div>
</template>

<script>
import HeaderBox from '@/components/HeaderBox.vue'
const columns = [
    {
      title: "序号",
      align: 'center',
      dataIndex: "index",
      scopedSlots: {
        customRender: "index"
      }
    },
  {
    title: 'Name',
    align: 'center',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Age',
    align: 'center',
    dataIndex: 'age',
  },
  {
    title: 'Address',
    align: 'center',
    dataIndex: 'address',
  },
   {
    title: '操作',
    align: 'center',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  }
];
const tableData = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
  },
  {
    key: '4',
    name: 'Disabled User',
    age: 99,
    address: 'Sidney No. 1 Lake Park',
  },
];
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      tableData,
      total: 0, 
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      isChecked: false, 
      selectedRowKeys:[],
    }
  },
  // 事件处理器
  methods: {
    handleChange(value) {
      // console.log(`selected ${value}`);
    },
    select(selectedRowKeys){
      this.selectedRowKeys = selectedRowKeys
      if(this.selectedRowKeys.length == this.tableData.length){
        this.isChecked = true
      }else{
        this.isChecked = false
      }
      // console.log(selectedRowKeys)
    },
    selectAll(selectedRows){
      this.selectedRows = selectedRows
    },
    //全选
    onChange(e) {
       if(e.target.checked == true){
         this.isChecked = true
         let key = this.tableData.map(item => {return item.key})
         this.selectedRowKeys = key
       }else{                   
         this.isChecked = false
         this.selectedRowKeys =[]
       }
      // console.log(`checked = ${e.target.checked}`);
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {
    rowSelection() {
      return {
        getCheckboxProps: record => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    }
  },
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped></style>
