var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 用户管理 ")]},proxy:true},{key:"input",fn:function(){return [_c('span',{staticClass:"tag"},[_vm._v("注册时间: ")]),_c('a-input',{attrs:{"placeholder":"请选择注册时间"}}),_c('a-input',{attrs:{"placeholder":"请输入关键字"}}),_c('a-select',{attrs:{"default-value":"lucy"},on:{"change":_vm.handleChange}},[_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-icon',{attrs:{"slot":"suffixIcon","type":"caret-down"},slot:"suffixIcon"}),_c('a-select-option',{attrs:{"value":"lucy"}},[_vm._v(" 行大运 ")]),_c('a-select-option',{attrs:{"value":"disabled","disabled":""}},[_vm._v(" 暴富 ")])],1),_c('a-button',{staticClass:"all_boder_btn"},[_vm._v("搜索")])]},proxy:true}])}),_c('a-table',{staticClass:"table-template",attrs:{"row-selection":{
     selectedRowKeys: _vm.selectedRowKeys,
     onChange: _vm.select,
     onSelectAll: _vm.selectAll,
     columnTitle:'选择'
    },"rowKey":"key","columns":_vm.columns,"data-source":_vm.tableData,"pagination":{
      total: _vm.total,
      showTotal: function (res) { return ("共" + _vm.total + "条"); },
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100'],
    }},scopedSlots:_vm._u([{key:"index",fn:function(item, row, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}}])},[_c('template',{slot:"footer"},[_c('a-checkbox',{staticClass:"allSellect",attrs:{"checked":_vm.isChecked},on:{"change":_vm.onChange}},[_vm._v(" 全选 ")])],1),_c('template',{slot:"operation"},[_c('span',{staticClass:"blueText"},[_vm._v(" 查看详情 ")])])],2),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" 1.是否可选：row-selection"),_c('br'),_c('br'),_vm._v("2.是否含表尾：slot=\"footer\""),_c('br'),_c('br'),_vm._v("3.操作栏蓝色字体：class=\"blueText\" ")])}]

export { render, staticRenderFns }